<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-sidebar
          id="tenant-create-sidebar"
          v-model="show"
          title="Create tenant"
          shadow
          backdrop
          right
          lazy
          @shown="resetForm"
        >

          <div class="px-3 py-2">

            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, max: 64 }"
            >
              <b-form-group
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  name="input-name"
                  placeholder="Enter name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Admin Email address"
              :rules="{ required: true, email: true, max: 256 }"
            >
              <b-form-group
                label="Admin Email address:"
                label-for="input-adminEmail"
                description="A user with this email will be created automatically with admin rights for the new tenant."
              >
                <b-form-input
                  id="input-adminEmail"
                  v-model="form.adminEmailAddress"
                  placeholder="Enter email of administrator"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-adminEmail-live-feedback"
                />
                <b-form-invalid-feedback id="input-adminEmail-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-checkbox
              v-if="form.adminEmailAddress"
              id="checkbox-sendInvitationEmail"
              v-model="form.sendInvitationEmail"
              name="checkbox-sendInvitationEmail"
            >
              Send invitation email
            </b-form-checkbox>
          </div>

          <template #footer="{ hide }">
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
              <div style="margin-left: auto">
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="isSaving"
                >
                  <b-spinner
                    v-if="isSaving"
                    small
                    label="Saving..."
                  />

                  Create
                </b-button>
                <b-button
                  class="ml-1"
                  @click="hide"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BButton, BSidebar, BForm, BFormInput, BFormGroup, BFormCheckbox, BFormInvalidFeedback, BSpinner,
} from 'bootstrap-vue'

// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TenantService from '@/service/tenant.service'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        name: null,
        adminEmailAddress: null,
        sendInvitationEmail: false,
      },
      show: false,
      isSaving: false,
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    resetForm() {
      this.form = {
        name: null,
        adminEmailAddress: null,
        sendInvitationEmail: false,
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    onSubmit() {
      const self = this
      this.isSaving = true

      TenantService.createAsync(this.form)
        .then(
          // eslint-disable-next-line no-unused-vars
          result => {
            self.$toast.success('Tenant has been created.', {
              icon: true,
            })

            self.show = false
            self.$emit('tenantCreated', {
              data: result,
            })
          },
          // eslint-disable-next-line no-unused-vars
          err => {
            const errors = mapServerFieldValidationErrors(err.response, {
              name: 'Name',
              adminEmailAddress: 'Admin Email address',
            })

            self.$refs.observer.setErrors(errors)

            self.$swal({
              title: 'Something went wrong!',
              text: err.response.data.error.message,
              icon: 'error',
            })
          },
        )
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}

</script>

// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {

  async triggerSynchronization(config) {
    const result = await axios.post('/core/crm/trigger/synchronize', {}, buildAxiosRequestConfig(config))
    return result.data
  },

}

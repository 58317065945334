<template>
  <div>
    <b-row>
      <b-col cols="12">
        <Grid
          class="grid-full-height"
          :data-items="result"
          :columns="columns"
          :column-menu="true"
          :take="dataState.take"
          :skip="dataState.skip"
          :sort="dataState.sort"
          :filter="dataState.filter"
          :group="dataState.group"
          :sortable="true"
          :reorderable="true"
          :resizable="true"
          :groupable="false"
          :pageable="true"
          :filterable="true"
          :page-size="50"
          @datastatechange="onDataStateChange"
          @filterchange="onFilterChange"
          @sortchange="onSortChange"
          @columnreorder="onColumnReorder"
        >
          <!-- Custom toolbar -->
          <GridToolbar>

            <toolbar-item-view-manager
              :grid-id="gridId"
              :columns="columns"
              :data-state="dataState"
              :original-columns="originalColumns"
              :original-data-state="originalDataState"
              :current-view-name="currentViewName"
              :current-view-id="currentViewId"
              @resetToDefaultViewClick="resetToDefaultView"
              @applyView="onApplyView"
            />

            <toolbar-item-column-manager
              :columns="activeColumns"
              :original-columns="originalColumns"
              @columnssubmit="columns = $event"
            />

            <toolbar-item-export-excel
              :data-items="dataItems"
              :columns="activeColumns"
              :data-state="dataState"
            />

            <div style="margin-left: auto">
              <b-button
                v-if="$can('Core.Miscs.TriggerCrmSynchronization') && $can('Core.Miscs.TriggerPortalOldUserSynchronization')"
                size="sm"
                variant="primary"
                @click="triggerSynchronization"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                />
                Synchronize
              </b-button>

              <b-button
                v-if="$can('Core.Tenants.Create')"
                v-b-toggle.tenant-create-sidebar
                class="ml-1"
                size="sm"
                variant="primary"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                />
                Create tenant
              </b-button>

              <TenantCreateSidebar
                @tenantCreated="onTenantCreated"
              />

              <toolbar-item-loading-indicator
                :is-loading="isLoading"
              />
            </div>
          </GridToolbar>

          <template v-slot:cellAdminUserTemplate="{ props }">
            <TenantListAdminUserCellTemplate
              :data-item="props.dataItem"
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
            />
          </template>

          <!-- Custom action cell -->
          <template v-slot:cellActionTemplate="{ props }">
            <DetailLinkActionCellTemplate
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
              to="host:manage:tenant-detail"
              :to-params="{ id: props.dataItem.id}"
            />
          </template>
        </Grid>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'

import {
  DetailLinkActionCellTemplate, TenantListAdminUserCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel, ToolbarItemLoadingIndicator,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import TenantService from '@/service/tenant.service'
import UserService from '@/service/user.service'
import CrmService from '@/service/crm.service'
import TenantCreateSidebar from './TenantCreateSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    Grid,
    GridToolbar,
    DetailLinkActionCellTemplate,
    TenantListAdminUserCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
    ToolbarItemLoadingIndicator,
    TenantCreateSidebar,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  data() {
    return {
      gridId: 'tenant',
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
          columnMenu: false,
        },
        {
          field: 'name',
          title: 'Name',
          orderIndex: 1,
          width: '400px',
        },
        {
          cell: 'cellAdminUserTemplate',
          title: 'Admin users',
          width: '400px',
          orderIndex: 2,
          filterable: false,
          columnMenu: false,
          sortable: false,
        },
        {
          field: 'assetCount',
          title: 'Assets',
          orderIndex: 3,
          width: '150px',
        },
        {
          field: 'assetWithAutosupportCount',
          title: 'Autosupport',
          orderIndex: 4,
          width: '150px',
        },
        {
          field: 'assetWithProActiveServiceCount',
          title: 'ProActive Service',
          orderIndex: 5,
          width: '150px',
        },
        {
          field: 'userCount',
          title: 'Users',
          orderIndex: 6,
          width: '150px',
        },
      ],
    }
  },
  computed: {
    result() {
      return process(this.dataItems, this.dataState)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true
      this.dataItems = await TenantService.getAllListAsync()
      this.isLoading = false
    },
    onTenantCreated() {
      this.loadData()
    },
    async triggerSynchronization() {
      await CrmService.triggerSynchronization()
      await UserService.triggerOldPortalUserSynchronization()

      this.$toast.info('Synchronization triggered. It can take up to some minutes.', {
        icon: true,
      })
    },
  },
}
</script>
